<template>
  <div class="container">

    <overview :achId="achId" />
    <vendor-submitted />
    <verification-call-info />
    <financial-information
      v-if="this.curUserType === 'AchAdministrator'"
      :achId="achId"
      @show-bank-info="showBankInfoSuccess"
      @image-viewed="imageViewedSuccess"
    />

    <div
      v-if="status === 'Authorized-Waiting FAMIS' && ['AchAdministrator'].includes(this.curUserType)"
      class="block"
    >
      <ValidationObserver
        v-slot="validation"
        tag="div"
      >
        <input-form :errors="validation.errors">
        <div class="block">
          Once you've entered this request into FAMIS, select "Request completed." To deny a request, you must also include a note.
        </div>
          <div class="field columns">
            <div class="column is-8">
              <vee-radio
                v-model="approveOrDeny"
                :options="approveDenyOptions"
                numOfColumns="2"
                title="Approve or deny"
                name="Approve or deny"
                rules="required"
              />
            </div>

            <div class="column is-6">
              <vee-text-area
                v-if="approveOrDeny === 'deny'"
                v-model="note"
                label="Note"
                name="Note"
                placeholder="Enter a note about this denial."
                :rules="denyRules"
              />
            </div>
          </div>

          <div class="control margin-top-sm">
            <button
              class="button is-primary"
              :class="{ 'is-loading': submitIsLoading }"
              @click.prevent="submitForm(validation)"
            >
              Submit
            </button>
          </div>

        </input-form>
      </ValidationObserver>

    </div>

    <audit-trail />
  </div>

</template>

<script>

import Overview from '@/pages/ach-request/sections/Overview';
import VendorSubmitted from '@/pages/ach-request/sections/VendorSubmitted';
import FinancialInformation from '@/pages/ach-request/sections/FinancialInformation';
import VerificationCallInfo from '@/pages/ach-request/sections/VerificationCallInfo';
import AuditTrail from '@/pages/ach-request/sections/AuditTrail';

import {
  TextArea,
  Radio,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import { required, email, ext, numeric, digits, is } from 'vee-validate/dist/rules';
const VeeTextArea = withValidation(TextArea);
const VeeRadio = withValidation(Radio);

export default {
  name: "AchRequestConfirmation",
  components: {
    Overview,
    VendorSubmitted,
    FinancialInformation,
    VerificationCallInfo,
    AuditTrail,
    VeeRadio,
    VeeTextArea,
  },
  props: {
    achId: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      approveOrDeny: null,
      submitIsLoading: false,
      note: null,
    };
  },
  computed: {
    approveDenyOptions() {
      let value = {
        'approve': 'Request completed',
        'deny': 'Deny request',
      };
      return value;
    },
    denyRules() {
      let value;
      if (this.approveOrDeny === 'deny') {
        value = 'required';
      } else {
        value = '';
      }
      return value;
    },
  },
  created() {
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
  },
  methods: {
    showBankInfoSuccess() {
      this.$emit('achApiCallSuccess');
    },
    imageViewedSuccess() {
      this.$emit('achApiCallSuccess');
    },
    async submitForm(validation) {
      const isValid = await validation.validate();
      // console.log('submitForm is running, validation:', validation, 'isValid:', isValid);
      if (!isValid) {
        return;
      }
      this.submitIsLoading = true;
      if (this.approveOrDeny === 'deny') {
        let formData = {
          achRequestId: this.achId,
          note: this.note,
        };
        const data = await this.$store.dispatch('ach/submitAchCompleteDenialWithNote', formData);
        if (data && data.status == 200) {
          this.submitIsLoading = false;
          this.$emit('achApiCallSuccess');
        } else {
          this.submitIsLoading = false;
          this.$warning('Denial failed.');
        }
      } else {
        let formData = {
          humanReadableId: this.achId,
        };
        const data = await this.$store.dispatch('ach/submitAchConfirmFamis', formData);
        if (data.status == 200) {
          this.submitIsLoading = false;
          this.$emit('achApiCallSuccess');
        } else {
          this.submitIsLoading = false;
          this.$warning('Please try again.');
        }
      }
    },
  },
};

</script>
